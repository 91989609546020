import React from 'react';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { Row, Col } from 'antd';
import Image from 'gatsby-image';
import parse from "html-react-parser"
import SEO from '../components/seo';
import { useStaticQuery, graphql } from "gatsby"

export default function WhyUsPage() {
  const { wpPage: {id, slug, title, content} } = useStaticQuery(graphql`
    query WhyQuery {
      wpPage(slug: {eq: "why-us"}) {
        id
        slug
        title
        content
      }
    }
  `)

  return (
    <>
    {/* <SEO title={page.title} description={page.content} /> */}
    <OverPack component="section" className="page-wrapper whyus text-center">
      <QueueAnim
        type="bottom"
        className="page"
        leaveReverse
        key="page3"
      >
        <h2 key="title">{parse(title)}</h2>
        <span key="line" className="separator" />
        <QueueAnim component={Row} type="bottom" key="content">
            <Col xs={24}>
                <div className="detail">{parse(content)}</div>
            </Col>
        </QueueAnim>
      </QueueAnim>
    </OverPack></>);
}